import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Section } from '../../components/Section'
import WelcomeBgImg from '../../images/welcomBg.jpg'
import { StaticImage } from "gatsby-plugin-image"
import CallIcon from '../../components/Icons/CallIcon'
import PrimaryLinkButton from '../../components/Button/PrimaryLinkButton'
import OfferSliderCarousel from '../../sections/home/offerSlider'
import BreakpointDown from '../../components/Media/BreakpointDown'
import BreakpointUp from '../../components/Media/BreakpointUp'
import { Link } from 'gatsby'

const CurveAination = keyframes`
  0%{
    transform:rotate(27deg)
  }
  50%{
    transform:rotate(211.8deg)
  }
  70%{
    transform:rotate(27deg)
  }
  100%{
    transform:rotate(211.8deg)
  }
`

const WelcomeSection = styled(Section)`
 &:before{
    background-size:cover;
    background-position: bottom;
    ${BreakpointDown.lg`
        display:none;
    `}
 }
`
const WelcomeWrapper = styled.div`
  min-height:calc(100vh - 0px);
  display:flex;
  position:relative;
  padding:50px 50px 20px 20px;
  flex-wrap:wrap;
  @media(max-width:1024px){
    min-height:auto;
  }
  ${BreakpointDown.lg`
      padding:0px 0px 0px 0px;
      background:#E3E4DF;
      margin-left: -15px;
      margin-right: -15px;
  `}
   ${BreakpointDown.md`
      padding:0px 0px 0px 0px;
  `}
`
const WelcomeLeft = styled.div`
 flex:0 0 40%;
 max-width:40%;
 width:100%;
 align-self:flex-end;

 @media(max-width:1024px){
  flex:0 0 50%;
  max-width:50%;
}

${BreakpointDown.lg`
   padding-left:0px;
   padding-right:0px;
   flex:0 0 100%;
   max-width:100%;
`}
`
const WelcomeRight = styled.div`
  flex:0 0 60%;
  max-width:60%;
  align-self:center;
  @media(max-width:1024px){
   margin-left:auto;
   margin-right:auto;
  }
  ${BreakpointDown.lg`
   padding-left:15px;
   padding-right:15px;
   margin-left:auto;
   margin-right:auto;
   flex:0 0 100%;
   max-width:100%;
`}
`
const WelcomeTop = styled.div`
 display:flex;
 justify-content:space-between;
 margin-bottom:30px;
 margin-top:120px;
 display:none;
 flex-wrap:wrap;
 ${BreakpointDown.lg`
   margin-bottom:0px;
   display:none;
 `}
`
const LogoWrapper = styled.div`
  position:relative;
  max-width:200px;
  width:100%;
  ${BreakpointDown.lg`
     max-width:120px;
     width:100%;
  `}
  `
const Cta = styled.div`
 display:flex;
 align-items:center;
 flex:1;
 justify-content: flex-end;
  `
const CtaTitle = styled.div`
color:#666666;
font-size:14px; 
line-height:1;
`
const CtaNumber = styled.div`
a{
  color:#000000;
  font-weight:900;
  line-height:1;
}
`
const CtaIcon = styled.div`
  position:relative;
  max-width:102px;
 `
const CtaDescription = styled.div`
  margin-left:15px;
 `

const WelcomeMiddle = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
height:calc(100vh - 350px); 
max-width:522px;
margin-top:60px;
margin-bottom:60px;
@media(min-width:2200px){
  max-width:700px;
}
@media(max-width:1024px){
   min-height:400px;
   padding-bottom:40px;
   margin-top:120px;
    min-height: auto;
    height:auto; 
  }
${BreakpointUp.sm`
 text-align:center;
`}
${BreakpointUp.lg`
 text-align:left;
`}
${BreakpointDown.lg`
     min-height: auto;
     height:auto;
     margin-top:60px;
     margin-bottom:0px;
     .btn{
      text-align:center;
     }
`}
  ${BreakpointDown.sm`
  margin-top:40px;
`}
.h1{
  font-weight:900;
  margin-bottom:0;
  line-height:1;
  text-transform:uppercase;
  span{
    color:#529A00;
    position:relative;
  }
}
.h2{ font-weight:900; line-height:1; margin-bottom:28px;}
`
const LineAnimation = styled.div`
    overflow: hidden;
    height: 33px;
    width: 100px;
    display: inline-block;
    position: absolute;
    top: -28px;
    ${BreakpointDown.lg`
     width: 60px;
      height: 26px;
      top: -23px; 
    `}
    ${BreakpointDown.sm`
      width: 56px;
      height: 24px;
    `}
    &:before{
      content:'';
      position: absolute;
      top: 0;
      left: -2px;
      width: 94px;
      height: 94px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 9px solid #eee;
      border-bottom-color: #529A00;
      border-right-color: #529A00;
      transform: rotate(211.8deg);
      animation: ${CurveAination} 3s ease-in-out both;
      transition: all 0.4s cubic-bezier(0.4, 0, 1, 1);
      ${BreakpointDown.lg`
          width: 60px;
          height: 60px;
          border-width: 6px;
          left: 0px;
          transform: rotate(223.8deg);
      `}
      ${BreakpointDown.sm`
          width: 52px;
          height: 52px;
        
      `}
    }
`

const OfferSliderWrapper = styled.div`
  flex:0 0 100%;
  width:100%;
  align-self: flex-end;
  margin-left:-50px;
  ${BreakpointUp.xl` 
   position:absolute;
   bottom:30px;
   left:0;
  `}
  @media(max-width:1024px){
   margin-right:auto;
   max-width:400px;
   margin-left:auto;
  }
  /* ${BreakpointDown.lg`
   margin-left:0; 
  `} */
`

const LeftImg = styled.div`
  position:relative;
  >div{
   width:100%;
 }
    ${BreakpointUp.lg`
       display:none;
    `}
`
const WelcomeRightItem = styled.div`
  ${BreakpointDown.lg`
      max-width:540px;
      margin-left:auto;
      margin-right:auto;
      margin-top:60px;
      text-align: left
  `}
  ${BreakpointDown.sm`
 margin-top:40px;
`}
`
const WelcomeHeading = styled.div`
 letter-spacing: 0.48px;
 ${BreakpointDown.sm`
     letter-spacing: 1.4px;
 `}
`
const WelcomeSubHeading = styled.div`
 ${BreakpointDown.lg`
    font-size:18px;
    margin-bottom:10px;
 `}
 ${BreakpointDown.sm`
  margin-top:10px;
 `}
`


const Welcome = () => (
  <WelcomeSection bgBefore={`url(${WelcomeBgImg})`}>
    <div className="container">
      <WelcomeWrapper>
        <WelcomeLeft>
          <LeftImg>
            <StaticImage
              src="../../images/welcome-banner-mobile.jpg"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Wyn Techonologies"
              placeholder="blurred"
            />
          </LeftImg>
        </WelcomeLeft>
        <WelcomeRight>
          <WelcomeRightItem>
            <WelcomeTop>
              <LogoWrapper>
                <StaticImage
                  src="../../images/logo.png"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Wyn Techonologies"
                  placeholder="blurred"

                />
              </LogoWrapper>
              <Cta>
                <CtaIcon>
                  <CallIcon />
                </CtaIcon>
                <CtaDescription>
                  <CtaTitle>Our Experts are Available</CtaTitle>
                  <CtaNumber>
                    <a className="h6" href="tel:3368990555">(336) 899-0555</a>
                  </CtaNumber>
                </CtaDescription>
              </Cta>
            </WelcomeTop>
            <WelcomeMiddle>
              <WelcomeHeading className="h1">Whatever <span><LineAnimation></LineAnimation> You </span> Need</WelcomeHeading>
              <WelcomeSubHeading className="h2">is what WYN provides!</WelcomeSubHeading>
              <p>Project Management, Technology Solutions, and Field Engineers trained & equipped to meet your individual needs & requirements.</p>
              <Link to="/about-us"><PrimaryLinkButton text="LEARN MORE ABOUT WYN" /></Link>
            </WelcomeMiddle>
          </WelcomeRightItem>
        </WelcomeRight>
        <OfferSliderWrapper>
          <OfferSliderCarousel />
        </OfferSliderWrapper>
      </WelcomeWrapper>
    </div>
  </WelcomeSection>
)
export default Welcome;