import { Link } from 'gatsby'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { Section } from '../../components/Section'
import DotteLineArrowIcon from '../../components/Icons/DotteLineArrowIcon'
import BreakpointDown from '../../components/Media/BreakpointDown'
import PrimaryLinkButton from '../../components/Button/PrimaryLinkButton'



const ServiceWrapper = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  background:#F8F8F8;
  flex-wrap:wrap;
`

const ServiceLeft = styled.div`
  flex:0 0 35%;
  max-width:100%;
  padding-left:15px;
  padding-right:15px;
  position: relative;
  @media(max-width:1280px){
    flex:0 0 40%;
  }
  ${BreakpointDown.lg`
    flex:0 0 100%;
    max-width:100%;
`}
 `
const StickyWrapper = styled.div`
    position:sticky;
    top:120px;
    z-index:3;
    @media(min-width:2200px){
      top:140px;
    }
 `

const ServiceList = styled.div`
 padding-right:20px;
 ${BreakpointDown.xl`
    display:none;
  `}
`

const ServiceListItem = styled.div`
  color:rgba(0,0,0, 0.5);
  border-bottom:1px solid #DDDDDD;
  padding-top:15px;
  padding-bottom:15px;
  position: relative;
  padding-left:40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media(min-width:2200px){
    padding-top:18px;
    padding-bottom:18px;
    font-size:22px;
  }
  @media(max-width:1440px){
    padding-left:6px;
    font-size:14px;
  }
  @media(max-width:1024px){
    line-height:1.5;
    padding-top: 10px;
    padding-bottom: 10px; 
  }
&.active{ 
  color:rgba(0,0,0, 1);
  &:before{
      content:'';
      background:#fff;
      position:absolute;
      left:0;
      right: -60px;
      top:1px;
      bottom:1px;
      z-index: -1;
      box-shadow: 19px 11px 35px #00000029;
  }
  &:after{
      content:'';
      width:0;
      height:0;
      border-left:31px #fff solid;
      border-bottom:31px transparent solid;
      border-top:31px transparent solid;
      position:absolute;
      right:-90px;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
  } 
}

 `
const DashLine = styled.span`
    content:'';
    position:relative;
    left:auto;
    top:50%;
    transform:translateY(-0%);
    width: 6px;
    height: 2px; 
    display:block;
    background:#000000;
    opacity:0.5;
    margin-right:15px;
`

const ServiceRight = styled.div`
  flex:0 0 65%;
  max-width:100%;
  padding-left:15px;
  padding-right:15px;
  @media(max-width:1280px){
    flex:0 0 60%;
  }
  ${BreakpointDown.xl`
    flex:0 0 100%;
  `}
 `

const Card = styled.div`
  display:flex;
  flex-direction:column;
  background:#fff;
  box-shadow: 0px 20px 85px #00000033;
  padding:60px 100px 60px 60px;
  @media(min-width:2200px){
    padding:80px 120px 80px 120px;
  }
  position:relative;
  &+&{
    margin-top:40px;
    ${BreakpointDown.md`
     margin-top:15px;
    `}
  }
  @media(max-width:1280px){
    padding:30px 100px 30px 50px;
  }
${BreakpointDown.md`
    padding:15px;  
`}
`
const CardHeader = styled.div`
 
`
const CardTitle = styled.div`
 
`
const CardBody = styled.div`
  
`

const CardImg = styled.div`
  position:relative;
  max-height:557px;
  overflow:hidden; 
  width:100%;
`
const ExploreMore = styled.div`
  color:#CCCCCC;
  letter-spacing: 1.8px;
  position:absolute;
  transform-origin:center;
  right:0;
  bottom: 117px;
  font-size:12px;
  font-family: "Nunito Sans", sans-serif;
  text-transform: uppercase;
  transform:rotate(-90deg);
  @media(max-width:1280px){
    bottom: 94px;
  }
  .btn{
    .text{
      letter-spacing: 1.8px; color:#CCCCCC;
      &:after{
        background:#CCCCCC;
      }
    }
    .icon{
      svg{
        transform:rotate(-90deg);
        margin-left:10px;
      }
    }
  }
  ${BreakpointDown.md`
   position:static;
   transform:none;
   order:2;
  `}
  a{
    color:#CCCCCC; 
  }
  `
const ServiceTitle = styled.h2`
 ${BreakpointDown.lg`
   text-align:center;
 `}
`

function OurService() {
  const ScrollAnim = (elem, val) => {
    document.querySelector("#service-" + val).scrollIntoView({ behavior: "smooth", block: "center", inline: "start" })
    document.querySelectorAll('.service-item').forEach(function (item, index) {
      item.classList.remove('active')
    })
    document.querySelector('#service-item-' + val).classList.add('active');
  }

  const handleScroll = () => {
    document.querySelectorAll('.service-card').forEach(function(item, index){
      var pos = document.querySelector("#"+item.getAttribute('data-active'))
      var rect = item.getBoundingClientRect();
       if(pos.offsetTop >= rect.top-160 && pos.offsetTop <= rect.bottom-190)
       {
         pos.classList.add('active')
       }
       else{
         pos.classList.remove('active')
       }
   });
  }
  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Section pt="70px" mpt="50px" mpb="60px" pb="100px" xxpt="150px" xxpb="150px" bgColor="#F8F8F8">
      <div className="container">
        <ServiceWrapper>
          <ServiceLeft>
            <StickyWrapper>
              <ServiceTitle className="h1">Our Services</ServiceTitle>
              <ServiceList>
                {[
                  'Access Control Installation & Hardware Sales',
                  'Access Point Installation & Wireless Heat Mapping',
                  'Business Telephone Systems & Contact Centers',
                  'Digital Signage Installation',
                  'Interactive Whiteboard Installation',
                  'ISP Service Brokerage & Cellular Network Backup Solutions',
                  'New Site Construction/Remodel/Move Equipment Installations & Service',
                  'Point-of-Sale Installation & Design',
                  'Staging/Configuration of IT Hardware/Peripherals',
                  'Structured Low Voltage Cabling (Network/Data/Voice) Design & Installation',
                  'Thermal Temperature Monitoring Solution Installation',
                  'Vehicle GPS Tracking Systems & Driver Safety Technology',

                ].map((item, index) => (
                  <ServiceListItem key={index} id={`service-item-${index}`} onClick={(e) => ScrollAnim(e, index)} className={`service-item`}><DashLine />{item}</ServiceListItem>
                ))}
              </ServiceList>
            </StickyWrapper>
          </ServiceLeft>
          <ServiceRight id="ServiceRight">
            {[
              {
                cardHeading: 'Access Control Installation & Hardware Sales',
                cardContent: 'Keep your physical and human assets safe by allowing WYN to install a modern Access Control system in your facility. Protect both your business and your property by controlling who has access to your building and when. You can monitor & track access in and out of secure areas, restrict access based on time or location, and also make use of multiple access options, including keys, PINs, proximity cards, fobs, mobile devices, and even biometrics. Plus, we offer multiple configurations, hardware options, and integration capabilities.',
                cardURL:"/access-control-installation-and-hardware-sales"
              

              },
              {
                cardHeading: 'Access Point Installation & Wireless Heat Mapping',
                cardContent: 'Setting up a wireless network offers many benefits and advantages for your business. It’s convenient, it’s cost-effective, and can also be configured with maximum security. WYN can install effective Wireless Access Points (or WAPs) throughout your facility. We can also perform a wireless heat mapping analysis of your facility to identify any dead spots or areas with excessively weak signal. With this information in hand, we can then provide the best WAP solutions to maximize your business network.',
                cardURL: '/access-point-Installation-wireless-heat-mapping'
              },
              {
                cardHeading: 'Business Telephone Systems & Contact Centers',
                cardContent: 'Looking for the best small business phone system? Cut costs on an older analog or digital PBX system & services by transitioning to a cloud-hosted VOIP solution. WYN has over 20 VOIP service provider options to offer you, so we can certainly help provide a perfectly tailored optimal solution to meet your needs, all at a cost you can afford. You won’t need to purchase a lot of expensive hardware, so you’ll be saving money right from the start. Plus, VOIP from WYN offers lots of bonus features you simply can’t get with an analog business phone system.',
                cardURL:'/business-telephone-systems-and-contact-centers'
               
              },
              {
                cardHeading: 'Digital Signage Installation',
                cardContent: 'Use digital signage to bring greater attention and deliver more vital information about the products and services you offer. Digital signage is a great way to engage better and inform your clients and potential clients. It is also an effective way to deliver pertinent information to both your customers and your employees. WYN can securely install digital signage in practically any location, especially for those sensitive high-traffic areas. We also offer a wide range of both single and multi-display configurations.',
                cardURL: '/digital-Signage-installation'
              },
              {
                cardHeading: 'Interactive Whiteboard Installation',
                cardContent: 'Interactive whiteboards – also known as SMART Boards – have become a standard for hands-on, intuitive learning in school classrooms across America. WYN is happy to partner with local school boards, colleges, universities, and educational entities to install interactive whiteboards for educational use. But interactive whiteboards have several business applications, as well. They’re a great asset for boardrooms, conference rooms, thinktanks, patient care facilities, and much more. And the best news? WYN Technologies can take care of all your interactive whiteboard needs!',
                cardURL:'/interactive-whiteboards-installation'
                
              },
              {
                cardHeading: 'ISP Service Brokerage & Cellular Network Backup Solutions',
                cardContent: "We can provide a range of competitive quotes for business internet service anywhere in the US, for individual and multi-site properties. Additionally, we’re happy to offer cellular network backup service solutions to make sure your business can keep running without a hiccup, even when there may be some local service outages in your area. Interested to learn more about our preferred ISP service brokerage and cellular network backup services? Call today, and let’s discuss all that we have to offer you and your business!",
                cardURL: '/point-of-sale-installation-and-design'
              },
              {
                cardHeading: 'New Site Construction/Remodel/Move Equipment Installations & Service',
                cardContent: 'Through our commitments to maintaining professionalism, resourcefulness, and clear communication with clients, we can offer a fresh perspective to act as technical consultants for your business when any issues arise. We can usually help you to cut unnecessary costs, as well! Our vast experience with new installations, remodels, de-installations, and scheduling for large-scale, multi-site, and even national rollouts gives us a unique ability to help you to meet project deadlines and even exceed your ultimate project goals. Additionally, we’re happy to provide onsite service calls, site surveys, and preventative maintenance to help keep all your operations running smoothly.',
                cardURL:'/new-site-construction-installation'
              },
              {
                cardHeading: 'Point-of-Sale Installation & Design',
                cardContent: 'The proper presentation and organizational flow of the Point of Sale (POS) systems within your store or retail environment allow for a more fluid business operation with your employees, as well as a better customer experience for your consumers. We can dovetail our services to flow effortlessly in conjunction with your company’s preferred specifications and best practices. In addition, WYN can fine-tune your installations to make sure your checkout areas demonstrate a design that’s both advantageously functional and also aesthetically pleasing at the same time.',
                cardURL: '/point-of-sale-installation-and-design'

              },
              {
                cardHeading: 'Staging/Configuration of IT Hardware/Peripherals',
                cardContent: 'When it comes to your business technology and how you need it to function, WYN is here to listen. And once we’re able to gather a clear understanding of your wants and needs, we’ll provide you with an optimal technological solution. We know that it’s important for you to be able to get your new technology up and running smoothly, and that’s why we make a point of preparing all your devices for service in a controlled environment before we ever arrive onsite to conduct your install. You can trust the quality of our staging and configuration services. We’re not just about equipment management; we’re about equipment maximization!',
                cardURL: '/staging-storage-inventory-and-depot-repair-services'
              },
              {
                cardHeading: 'Structured Low Voltage Cabling (Network/Data/Voice) Design & Installation',
                cardContent: 'What’s the key to ensuring the optimal performance of your network cabling infrastructure? Organizing and designing everything properly on the front end! Longer times spent troubleshooting result in more unnecessary downtime for your network, which ultimately leads to a decrease in profitability for your business. WYN delivers clean, efficient structured cabling installations and network racking solutions, along with effective remediation services for your existing cabling. The end result? Better ergonomic design, decreased downtime, and a cabling network that can best serve your needs. IT hardware asset management is really one of our specialties!',
                cardURL: '/structured-low-voltage-cabling-network-data-voice'
              },
              {
                cardHeading: 'Thermal Temperature Monitoring Solution Installation',
                cardContent: 'Thermal Temperature Monitoring technology enables quick detection of any elevated skin temperatures. Thermal imaging equipment can be installed and implemented in varied environments,  including airports, hospitals, clinics, office buildings, schools, and any public gathering locations. WYN Technologies has installed these screening tools for school systems, medical clinics, manufacturing businesses, government facilities, and restaurants across North Carolina and beyond. This technology can also be paired with network video monitors and recorders to enable monitoring from a safe distance. In addition, you can program the system to send alerts about individuals who display elevated temperatures, as well as about individuals who aren’t properly masked.',
                cardURL:'/thermal-temperature-monitoring-installation'
              
              },
              {
                cardHeading: 'Vehicle GPS Tracking Systems & Driver Safety Technology',
                cardContent: 'Are you ready to keep a better account of vehicle mileage, fuel cost, and the current location of your fleet? And just as importantly, how’d you like to be able to accurately track, schedule, and report on the time-to-destination for your drivers through an effective vehicle GPS tracking system? WYN can deliver the right technological solution to meet these needs for your business. Ask us about how to better protect your assets and personnel by implementing mobile technology and applications to help avoid driver distractions, too.',
                cardURL: '/vehicle-gps-tracking-systems-and-driver-safety'
              }
            ].map((item, index) => {
              return (
                <Card key={index} id={`service-${index}`} className="service-card" data-active={`service-item-${index}`}>
                  <ExploreMore><Link to={item.cardURL}><PrimaryLinkButton text="Exlore More" icon={<DotteLineArrowIcon />} /></Link></ExploreMore>
                  <CardHeader>
                    <CardTitle className="h3">{item.cardHeading}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <p>{item.cardContent}</p>
                    <CardImg>
                    {index === 0 && <StaticImage
                        src="../../images/access-control-home.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      }
                    {index === 1 && <StaticImage
                        src="../../images/access-point-heat-mapping-home.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      }
                        {index === 2 && <StaticImage
                        src="../../images/business-telephone-systems-home.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      }
                      {index === 3 && <StaticImage
                        src="../../images/digital-signage-home.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      }
                        {index === 4 && <StaticImage
                        src="../../images/interactive-whiteboards.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      }
                       {index === 5 && <StaticImage
                        src="../../images/isp-service-home.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      }
                      {
                        index === 6 && <StaticImage
                          src="../../images/project-management-home.jpg"
                          quality={100}
                          formats={["AUTO", "WEBP", "AVIF"]}
                          alt="Our Services"
                          placeholder="blurred"
                        />
                      }
                       {/* {index === 7 && <StaticImage
                        src="../../images/camera-system-home.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      } */}
                       {index ===7 && <StaticImage
                        src="../../images/point-of-sale-installation-home.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      }
                      {index === 8 && <StaticImage
                        src="../../images/staging-storage-inventory-home.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      }
                      {index === 9 && <StaticImage
                        src="../../images/cabling-home.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      }
                     
                     
                      {index === 10 && <StaticImage
                        src="../../images/thermal-temperature.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      }
                      {index === 11 && <StaticImage
                        src="../../images/vehicle-gps-home.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Our Services"
                        placeholder="blurred"
                      />
                      }
                    </CardImg>
                  </CardBody>
                </Card>
              )
            }
            )}
          </ServiceRight>
        </ServiceWrapper>
      </div>
    </Section>
  )
}
export default OurService