import React from "react"
import styled from "styled-components"
import { Section } from "../../components/Section"
import IndustrySliderCarousel from "./industrySlider"
import { StaticImage } from "gatsby-plugin-image"
// import DotteLineArrowIcon from "../../components/Icons/DotteLineArrowIcon"
import Link from "gatsby-link"
import BreakpointDown from "../../components/Media/BreakpointDown"
const SectionZindex = styled(Section)`
   z-index:2;
`

const IndustryBrandWrapper = styled.div`
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap:wrap;
  ${BreakpointDown.lg`
  flex-direction:column;
  `} 
  ${BreakpointDown.md`
     margin-left: -15px;
     margin-right: -15px;
  `}
`
const IndustryBrandItem = styled.div`
  flex: 0 0 50%;
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
  position:relative;
  ${BreakpointDown.lg`
    flex: 0 0 100%;
    width: 100%;
  `}
`
const Card = styled.div`
  padding: 86px;
  box-shadow: 0px 20px 85px rgba(0, 0, 0, 0.20);
  text-align:center;
  height:100%;
  background:#FFFFFF;
  @media(max-width:1280px){
    padding:50px;
  }
  @media(max-width:1024px){
    padding: 30px 20px;
  }
  ${BreakpointDown.lg`
    margin-bottom:25px;
    padding-bottom:30px;
  `}
  ${BreakpointDown.sm`
    padding: 30px 10px;
  `}
`
const CardHeader = styled.div`
margin-bottom:25px;
`
const CardTitle = styled.div`
  margin-bottom: 0;
  line-height: 48px;
`
const CardBody = styled.div``
const ImgWrapper = styled.div`
  position: relative;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  img {
    filter: grayscale(100%);
     transition: all 0.3s;
  }
`
const BrandListign = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 490px;
  margin-left: auto;
  margin-right: auto;
`
const BrandItem = styled.div`
  padding: 40px;
  flex: 0 0 50%;
  border-bottom: 1px;
  border: 1px solid #dddddd;
  min-height: 190px;
  transition: all 0.4s linear;
  position: relative;
  @media(max-width:1280px){
    padding: 10px;
  }
  ${BreakpointDown.md`
     min-height: 104px;
  `}
  &:nth-child(5) {
    border-bottom: 0;
  }
  &:before{
    content:'';
    transition:all 0.4s;
    position:absolute;
    left:0;
    top:0;
    bottom:0;
    right:0;
    background:#fff;
    box-shadow: 0px 20px 85px rgba(0, 0, 0, 0.20);
    z-index:1;
    transform:scale(0);
    border: 1px solid #c3c2c2;
  }
  &:hover {
    img {
      filter: grayscale(0);
    }
    &:before {
      transform: scale(1.1);
    }
  }
  &:nth-child(odd) {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    &:last-child {
      border-bottom: 0;
    }
  }
  &:nth-child(even) {
    border-right: 0;
    border-top: 0;
    &:last-child {
      border-bottom: 0;
    }
  }
`
const Item = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content:center;
`
// const CardFooter = styled.div`
//   margin-top: 40px;
//   font-size: 12px;
//   font-weight: bold;
//   max-width: 490px;
//   margin-left: auto;
//   margin-right: auto;
//   @media(min-width:2200px){
//     font-size: 14px;
//   }
//   a {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     color:#000000;
//     ${BreakpointDown.md`
//       text-align:left;
//       line-height:20px;
//     `}
//   }
//   svg {
//     margin-left: 10px;
//     transform: rotate(-90deg);
//     ${BreakpointDown.md`
//       margin-left: 25px;
//     `}
//   }
// `

const QuoteCircle = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 100%;
  background: #fff;
  border: 1px solid #dddddd;
  position: absolute;
  right: 0;
  margin-right: -45px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  font-weight: 900;
  top: 90px;

  @media(max-width:1024px){
    width: 60px;
    height: 60px;
    top: 90px;
    margin-top:-20px;
    margin-right: -32px
  }
  ${BreakpointDown.lg`
    top: 100%;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    margin-top: -43px;
  `}
`

function IndustryBrand() {
  return (
    <SectionZindex pt="0px" pb="0px" mpb="0px" xxpt="0px" xxpb="100px" bgColor="#F8F8F8">
      <div className="container">
        <IndustryBrandWrapper>
          <IndustryBrandItem>

            <Card>
              <CardHeader>
                <CardTitle className="h1">Industries We Serve</CardTitle>
                <p>We can provide the services mentioned above to essentially any business or organization, and we’re happy to do so! Here are just a few of the industries we partner with in particular:</p>
              </CardHeader>
              <CardBody>
                <IndustrySliderCarousel />
              </CardBody>
            </Card>
            <QuoteCircle>&</QuoteCircle>
          </IndustryBrandItem>

          <IndustryBrandItem>
            <Card>
              <CardHeader>
                <CardTitle className="h1">Brands We Trust</CardTitle>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and type setting industry.</p> */}
              </CardHeader>
              <CardBody>
                <BrandListign>
                  <BrandItem>
                    <Item>
                      <Link to="/zultys">
                        <ImgWrapper>
                          <StaticImage
                            src="../../images/brand-1.png"
                            quality={100}
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Brands We Trust"
                            placeholder="blurred"
                          />
                        </ImgWrapper>
                      </Link>
                    </Item>
                  </BrandItem>
                  <BrandItem>
                    <Item>
                      <Link to="/intermedia">
                        <ImgWrapper>
                          <StaticImage
                            src="../../images/brand-7.png"
                            quality={100}
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Brands We Trust"
                            placeholder="blurred"
                          />
                        </ImgWrapper>
                      </Link>
                    </Item>
                  </BrandItem>
                  <BrandItem>
                    <Item>
                      <Link to="/zyxel">
                        <ImgWrapper>
                          <StaticImage
                            src="../../images/brand-3.png"
                            quality={100}
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Brands We Trust"
                            placeholder="blurred"

                          />
                        </ImgWrapper>
                      </Link>
                    </Item>
                  </BrandItem>
                  <BrandItem>
                    <Item>
                      <Link to="/staff-alerter">
                        <ImgWrapper>
                          <StaticImage
                            src="../../images/brand-4.png"
                            quality={100}
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Brands We Trust"
                            placeholder="blurred"

                          />
                        </ImgWrapper>
                      </Link>
                    </Item>
                  </BrandItem>
                  <BrandItem>
                    <Item>
                      <Link to="/dahua">
                        <ImgWrapper> 
                          <StaticImage
                            src="../../images/brand-2.png"
                            quality={100}
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Brands We Trust"
                            placeholder="blurred"

                          />
                        </ImgWrapper>
                      </Link>
                    </Item>
                  </BrandItem>
                  <BrandItem>
                    <Item>
                      <Link to="/honeywell">
                        <ImgWrapper>
                          <StaticImage
                            src="../../images/brand-6.png"
                            quality={100}
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="Brands We Trust"
                            placeholder="blurred"

                          />
                        </ImgWrapper>
                      </Link>
                    </Item>
                  </BrandItem>
                </BrandListign>
                {/* <CardFooter><Link to="/">Dahua Technology Co. Ltd. Lorem Ipsum is simply dummy text.<DotteLineArrowIcon /></Link></CardFooter> */}
              </CardBody>
            </Card>
          </IndustryBrandItem>
        </IndustryBrandWrapper>
      </div>
    </SectionZindex>
  )
}

export default IndustryBrand
