import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Welcome from "../sections/home/welcome"
import OurPhilosophy from '../sections/home/ourPhilosophy'
import OurService from "../sections/home/ourServices"
import IndustryBrand from "../sections/home/industriesBrand"
import GetStarted from '../components/GetStarted'
import CaseStudies from "../components/CaseStudies"
import LetastArticles from "../components/LetastArticles"
import styled from 'styled-components'
import { Section, ComingSoon, Tags } from '../components/Section'
import BreakpointDown from "../components/Media/BreakpointDown"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import SecondaryLinkButton from '../components/Button/SecondaryLinkButton'
//Letast BLogs


const Card = styled.div`
  padding: 70px 50px ;
  background:#fff;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  /* @media (min-width:2200px){
    padding: 20px 15px;
  } */
  @media(max-width:1440px){
    padding: 20px 14px; 
  }
  ${BreakpointDown.lg`
   text-align:center;
 `}
  .btn{
    margin-top:15px;
  }
`
const CardTitle = styled.div`
 margin-bottom:6px;
 font-weight:bold;
`
const CardImg = styled.div`
  position: relative;
  max-width:100%;
  width:100%;
  margin-top:26px;
  .gatsby-image-wrapper{
    width:100%;
  }
`
const Date = styled.div`
  color:#666666;
  font-size:10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (min-width:2200px){
    font-size:14px;
  }
`
const SectionHeader = styled.div`
 text-align:center;
 .h1{ margin-bottom:0px;}
 ${BreakpointDown.md`
 p{
   margin-bottom:0;
 } 
 `}
`
const Title = styled.h2`

`

const Blogs = styled.div`
 display: flex;
 margin-inline:-15px;
 flex-wrap:wrap;
 margin-top:50px;
`
const BlogItems = styled.div`
 flex:0 0 auto;
 width:33.3333%;
 padding-inline:15px;
 @media(max-width:767px){
   width:100%;
  & + &{
    margin-top:20px;
  }
 }
`

const IndexPage = () => {
  return (
    <Layout pageName="home">
      <SEO title="Surveillance Solutions, Business Phone Systems, high Speed Fiber Cabling, Digital Signage, Access Control, Network Backup Solutions from NC to US East Coast" description ="WYN Technologies is a leader in cabling, monitoring and communication technologies. It provides reliable technology, phone, and cabling services to Schools, Grocery, Retail, Manufacturing, Hospitality and Aerospace industries." />
      <Welcome />
      <OurPhilosophy />
      <OurService />
      <IndustryBrand />
      <GetStarted />
      <CaseStudies />
      <Section pt="80px" mpt="40px" bgColor="#F8F8F8" pb="50px" mpb="40px" xxpt="100px" xxpb="100px">
        <div className="container">
          <SectionHeader>
            <Title className="h1">Latest Articles & Blogs</Title>
          </SectionHeader>
          <Blogs>
            <BlogItems>
                  <Card> 
                    <Date>8 July, 2021</Date>
                    <CardTitle to="/move-your-business-into-the-future-with-voip-phone-systems" className="h6">Move Your Business Into the Future with VoIP Phone Systems</CardTitle>
                    <Tags to="/">Phone Systems</Tags> <Tags to="/">Business Communication</Tags>
                    <CardImg>
                      <StaticImage
                        src="../images/blog_3_blog-card.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="VoIP Phone Systems blog Card"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <Link to="/move-your-business-into-the-future-with-voip-phone-systems"><SecondaryLinkButton text="Read Full Blog" /></Link>
                  </Card>
              </BlogItems>
            <BlogItems>
                  <Card>
                    <Date>8 July, 2021</Date>
                    <CardTitle to="/everything-you-need-to-know-about-getting-an-access-control-system" className="h6">Everything You Need to Know About Getting an Access Control System</CardTitle>
                    <Tags to="/">Access Control System</Tags> <Tags to="/">Business Safety and Security</Tags>
                    <CardImg>
                      <StaticImage
                        src="../images/blog_1_blog-card.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Access Control System blog Card"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <Link to="/everything-you-need-to-know-about-getting-an-access-control-system"><SecondaryLinkButton text="Read Full Blog" /></Link>
                  </Card>
                  </BlogItems>
            <BlogItems>
                  <Card>
                    <Date>8 July, 2021</Date>
                    <CardTitle to="/get-the-right-point-of-sale-system-for-your-company-with-wyn-technologies" className="h6">Get the Right Point-of-Sale System for Your Company with WYN Technologies</CardTitle>
                    <Tags to="/">Point-of-Sale Systems</Tags> <Tags to="/">Retail Technology</Tags>
                    <CardImg>
                      <StaticImage
                        src="../images/blog_2_blog-card.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="VoIP Phone Systems blog Card"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <Link to="/get-the-right-point-of-sale-system-for-your-company-with-wyn-technologies"><SecondaryLinkButton text="Read Full Blog" /></Link>
                  </Card>
                  </BlogItems>
          </Blogs>
        </div>
      </Section>
    </Layout> 
  )
}

export default IndexPage
