import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BreakpointDown from "../../components/Media/BreakpointDown";
import PrimaryLinkButton from "../../components/Button/PrimaryLinkButton";
import { Link } from "gatsby"
const SlickSliderInner = styled.div`
  position: relative;
  border-radius: 5px;
  padding: 28px 15px;
  z-index: 2;
  border-radius: 110px;
  ${BreakpointDown.md`
      padding:25px 15px;
   `}
.slick-dots{
  display: flex !important;
  flex-direction: column;
  right: -75px;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  width: auto;
  ${BreakpointDown.md`
    right:-35px;
  `}
    li{
       button{
          width: 10px;
          height: 10px;
         &:before{
            background:#fff;
            color:#fff;
            border:3px #529A00 solid; 
            width: 10px;
            height: 10px;
            border-radius:100%;
            line-height:1;
            content: '';
          }
       }
      }
}
`;
const SlickSlider = styled.div`
    max-width: 458px;
    position: relative;
    margin-left: auto;
    padding-right: 90px;
    @media (min-width:2400px){
      max-width: 560px;
    }
    ${BreakpointDown.lg`
        padding-right:0px;
        margin-left:auto;
        margin-right:auto;
        margin-top: 10px; 
    `}
    ${BreakpointDown.md`
      margin-top: -40px;
      max-width: 317px;
    `}

  .slick-slider {
    .slick-arrow {
      display: none !important;
    }
  }
`;
const SlickItem = styled.div`
  display: block !important;
  outline: none;
  max-width: 100%;
  font-size: 18px;
`;
const ContentWrapper = styled.div`
 color:#333333;
 font-size:18px;
 line-height:30px;
`
const CustomerTitle = styled.div`
  font-weight:900;
  margin-bottom:10px;
  margin-top:10px;
  text-align:center;
`
const Text = styled.div`
 font-style:italic;
 ${BreakpointDown.md`
   font-size: 16px;
   line-height:26px;
  `}
`
const CustomerName = styled.div`
 font-size:12px;
 margin-top:10px;
 letter-spacing: 1.8px;
 margin-bottom: 0;
 ${BreakpointDown.lg`
    
 `}
`

const BtnWrapper = styled.div`
  text-align: center;
  margin-top: -26px;
  position: relative;
  z-index: 2;
`
const CustomerAddress = styled.div`
 color:#999999;
 font-size:14px;
 font-weight:bold;
 line-height:20px;
 margin-top:0px;
`


export default class CustomerSlider extends Component {
  render() {
    const settings = {
      slidesToShow: 1,
      infinite: true, 
      dots: true,
      arrow: false,
      speed: 400,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows: false,
            centerMode: true,
            centerPadding: "0",
            infinite: true,
          },
        },
      ],
    };
    return (


      <SlickSlider>
        <SlickSliderInner>
        <CustomerTitle className="h4">Customer Reviews…</CustomerTitle>
          <Slider {...settings} ref={(c) => (this.slider = c)}>
            {[
              {
                review: '“Wyn Technologies did an excellent job providing and installing the camera surveillance system at our Industrial Park in High Point.  We no longer struggle with blind spots as their 180-degree cameras pick up every area.  The night vision is remarkable, appearing on screen as if it is day light hours.  Wyn’s customer service is top notch!  I regularly recommend their services to our clients and tenants.”',
                auther: '- Gretchen Thiessens',
                address:'The Charis Companies',
              },
              {
                review: '“The temperature monitoring solution that is available thru Wyn Technologies was our top choice.  Their products, professional knowledge, and customer service are excellent. I would highly recommend their services to assist in promoting a healthy and safe environment.”',
                auther: '- James R. Bunch',
                address:'Assistant Superintendent, Perquimans County Schools',
              }
            ].map((item, index) => (
              <SlickItem key={item}>
                <ContentWrapper>
                  <Text>{item.review}</Text>
                  <CustomerName className="h4">{item.auther}</CustomerName>
                  <CustomerAddress className="h4">{item.address}</CustomerAddress>
                </ContentWrapper>
              </SlickItem>
            ))}
          </Slider>
        </SlickSliderInner>
        <BtnWrapper>
          <Link to ="/review"><PrimaryLinkButton text="View All Reviews" /></Link>
        </BtnWrapper>
      </SlickSlider>


    );
  }
}
