import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BreakpointDown from "../../components/Media/BreakpointDown";
import PrimaryLinkButton from '../../components/Button/PrimaryLinkButton'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import BreakpointUp from "../../components/Media/BreakpointUp";

const SlickSliderInner = styled.div`
  position: relative;
  max-width: 468px;
  padding: 0px 0px 0;
  z-index: 2;
  margin-right:auto;
  margin-left:auto;
  position: relative;
  border-bottom:1px #CCCCCC solid;
  ${BreakpointDown.md`
      border-bottom:0;
   `}
`;
const SlickSlider = styled.div` 
  margin-top: 0px;
  .slick-slider {
    .slick-arrow {
      display: none !important;
    }
  }
`;
const SlickItem = styled.div`
  display: block !important;
  outline: none;
  max-width: 100%;
  font-size: 18px;
`;


const Card = styled.div`
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.2);
  border: 1px solid #CCCCCC;
  padding: 40px 40px 40px;
  position:relative;
  z-index:2;
  background:#fff;
  max-width: 396px;
  margin-left:auto;
  margin-right:auto;
  ${BreakpointUp.sm`
     border-bottom:0;
  `}
  ${BreakpointDown.md`
    padding: 15px 15px 15px;
  `}
`
const CardInner = styled.div`
  box-shadow: 0px 10px 9px rgb(0, 0, 0, 0.16);
  border: 1px solid #CCCCCC;
  opacity: 0.6;
  position: absolute;
  left: 9px;
  right: 9px;
  bottom: -1px;
  top: 60px;
  ${BreakpointDown.md`
   display:none;
  `}
`
const CardImg = styled.div`
  position:relative;
  max-width:300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom:30px;
  `
const CardTitle = styled.div`
  margin-bottom:0;
`
const CardContent = styled.div`
  line-height:26px;
  margin-bottom:10px;
  font-size: 16px;
  ${BreakpointDown.sm`
    line-height:22px;
    font-size: 14px;
  `}
`
const CardBtn = styled.div`

`
const NavForWrapper = styled.div`
    display: flex;
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
    margin-top:50px;
    overflow-x:hidden;
    ${BreakpointDown.md`
        max-width: 340px;
        margin-top:15px;
    `}
    .slick-slider{
      /* width:100%; */
      .slick-track{
        /* width:auto !important; */
      }
    .slick-list{
      /* width:100%;
      display:flex;
      justify-content:center; */
    
      .slick-slide{
        margin-left:20px;
        ${BreakpointDown.md`
           margin-left: 8px;
        `}
        &:first-child{
          margin-left:0;
        }
        *{
            &:hover,
            &:focus,
            &:active {
              outline: none !important;
            }
        }
        &.slick-current{
          .nav-for-item{
            color:#000000;
            &:after{
            content:'';
            display:block;
            width:100%;
            height:2px;
            background:#000000;
           }
          }
        }
      }
    }
  }
`
const NavForItem = styled.div`
  font-size:12px;
  font-weight:bold;
    margin-left:20px;
    position:relative;
    color: #666666;
    cursor: pointer;
    @media(min-width:2200px){
      font-size:16px;
    }
    ${BreakpointDown.md`
     font-size:11px;
    `}
    &:first-child{
      margin-left:0;
    }
`

export default class IndustrySliderCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }
  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const settings = {
      slidesToShow: 1,
      infinite: true,
      arrow: false,
      speed: 1200,
      loop:true,
    };

    return (
      <>
        <SlickSlider>
          <SlickSliderInner>
            <CardInner />
            <Card>
              <Slider {...settings} asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}>
                {[{
                  title: 'Aerospace',
                  description: 'Aerospace facilities often have some pretty specific needs when it comes to communication technology, networking, RFID tracking equipment, and IT support. WYN is here to meet these needs, and more!',
                  url: '/aerospace'
                },
                {
                  title: 'Convenience Stores (C-Stores)',
                  description: 'We’ve worked with many Convenience Stores (C-Stores) to provide digital signage, wifi access points, VOIP phone systems, data cabling, surveillance, drive-thru technologies, and other needed technological solutions.',
                  url: '/convenience-stores'
                },
                {
                  title: 'Education',
                  description: 'We love partnering with schools and educational institutions to provide technologies that boost the learning experience for students and technical solutions to benefit educators and administrators.',
                  url: '/education'
                },
                {
                  title: 'Grocery',
                  description: 'We work with the grocery industry to deliver technological solutions that improve the customer and the employee experience. We install POS systems, network solutions, wifi, surveillance, and much more.',
                  url: '/grocery'
                },
                {
                  title: 'Hospitality',
                  description: 'We serve the hospitality industry by providing needed technologies, including POS systems, data cabling and networking, VOIP phone systems, video surveillance, wifi access points, and other valuable services.',
                  url: '/hospitality'
                },
                {
                  title: 'Manufacturing',
                  description: 'We’ve installed helpful technologies for several manufacturing and production facilities. We can help you by providing complete network solutions, VOIP phone systems, data closets, video surveillance, GPS tracking, and more.',
                  url: '/manufacturing'
                },
                {
                  title: 'Medical',
                  description: 'Having access to the right technological solutions is essential for modern medical care providers. We can install and provide IT support to whatever you need, including instant body temperature measurement equipment.',
                  url: '/medical'
                },
                {
                  title: 'Quick Service Restaurants (QSR)',
                  description: 'Quick service restaurants (or QSRs) rely on modern technology to help serve customers in a fast, efficient manner. We can help with networking, POS systems, drive thru technologies, and more!',
                  url: '/quick-serve-restaurants'
                },
                {
                  title: 'Retail',
                  description: 'We partner with retail organizations to provide a wide range of needed technologies, including everything from networking, to wifi, to POS systems, to surveillance, to digital signage, and more!',
                  url: '/retail'
                },
                {
                  title: 'Warehousing ',
                  description: 'There are several modern technologies available that benefit the warehousing industry. We can help with network installation, asset management, cameras & surveillance, security, delivery processes, GPS tracking, and more.',
                  url: '/warehousing'
                },
                ].map(item => (
                  <SlickItem key={item}>
                    <CardImg>
                      <StaticImage
                        src="../../images/sml-img.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Industry"
                        placeholder="blurred"
                      />
                    </CardImg>
                    {item.title && <CardTitle className="h3">{item.title}</CardTitle>}
                    {item.description && <CardContent>Ask us about our Network Security plans for SMBs and our Cloud Service Solutions including Cloud Server Hosting.</CardContent>}
                    <CardBtn>
                      <Link to={item.url}><PrimaryLinkButton text="View More Detail" /></Link>
                    </CardBtn>
                  </SlickItem>
                ))}
              </Slider>
            </Card>
          </SlickSliderInner>
        </SlickSlider>
        <NavForWrapper>
          <Slider
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={6}
            swipeToSlide={true}
            focusOnSelect={true} variableWidth={true} infinite={true}>
            {[
              'Aerospace',
              'Convenience Stores (C-Stores)',
              'Education',
              'Grocery',
              'Hospitality',
              'Manufacturing',
              'Medical',
              'Quick Service Restaurants (QSR)',
              'Retail',
              'Warehousing '
            ].map(item => (
              <NavForItem className="nav-for-item" key={item}>{item}</NavForItem>
            ))}
          </Slider>
        </NavForWrapper>
      </>
    );
  }
}

