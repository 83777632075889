import React from 'react'
import { Section } from '../../components/Section'
import styled from 'styled-components'
import PrimaryLinkButton from '../../components/Button/PrimaryLinkButton'
import CustomerSlider from './customerSlider'
import IqBgImg from '../../svg/iq.svg'
import IbgImg from '../../images/l.png'
import QbgImg from '../../images/q.png'
import BreakpointDown from '../../components/Media/BreakpointDown'
import { Link } from 'gatsby'

const ShadowWrapper = styled.div`
  box-shadow: 0 35px 41px -23px #00000033;
  padding-bottom:0px;
  position:relative;
  z-index:2;
  .container{display:flex; align-items:center;} 
  @media(min-width:2200px){
    min-height:80vh;
    display:flex;
  }
`
const Philosophy = styled.div`
  display:flex;
  flex-wrap:wrap;
  margin-left:-45px;
  margin-right:-45px;
  justify-content:space-between;
  min-height: 809px;
  padding-bottom:50px;
  position:relative; 
  align-items:center;
  z-index:2;
  @media(min-width:2400px){
    width:100%; 
  }

    
 ${BreakpointDown.xl`
    margin-left:-15px;
    margin-right:-15px;
    text-align:center;
 `}
 ${BreakpointDown.md`
    text-align:center; 
 `}


 &:before{
   content:'';
    background:url(${IqBgImg}) center right no-repeat;
    background-position: 97% 100%;
    position:absolute;
    left:0;
    top:0;
    bottom:0;
    right:0;
    background-size:contain;
   ${BreakpointDown.lg`
    display:none;
   `}
   @media(min-width:2200px){
    background-position: 92% 100%;
   }
 }

`
const LeftWrapper = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:45px;
 padding-right:45px;
 position: relative;
 align-self: center;
 ${BreakpointDown.lg`
  flex:0 0 100%;
 width:100%;
 padding-left:15px;
 padding-right:15px;
 &:before{
    content:'';
    background:url(${IbgImg}) center right no-repeat;
    background-position: center center;
    position: absolute;
    left: 12px;
    top: 0;
    bottom:0px;
    right: 0;
    background-size: 20%;
    ${BreakpointDown.md`
      background-position: top center;
      background-size: 48%;
    `}
 }
 `}
`
const InnerContent = styled.div`
    position: relative;
    z-index:1;
    padding-top:50px;
    padding-bottom:50px;
    ${BreakpointDown.md`
      padding-top:20px;
    padding-bottom:40px;
    `}
`
const RightWrapper = styled.div`
  flex:0 0 50%;
  width:50%;
  padding-left:45px;
  padding-right:45px; 
  position:relative;
 ${BreakpointDown.lg`
    flex:0 0 100%;
    width:100%;
    padding-left:15px;
    padding-right:15px;
    &:before{
      content:'';
      background:url(${QbgImg}) center right no-repeat;
      background-position: bottom center;
      position: absolute;
      left: 0;
      top: 0;
      bottom: -90px;
      right: 0;
      background-size: 57%;
      ${BreakpointDown.lg`
        background-size: 54% 94%;
        bottom: -90px;
    `}
      ${BreakpointDown.md`
        bottom: -107px;
        background-size: 100% 100%;
    `}
  }
  ${BreakpointDown.md`
     padding-top: 66px;
  `}
 `}

`
const OurphilosophyTitle = styled.div`
   font-weight:900;
   line-height:1;
`
const QualityTitle = styled.h1`
  color:#529A00;
  font-weight:900;
  line-height:1;
  margin-bottom:5px;
  margin-top:5px;
  text-transform:uppercase;
  letter-spacing: 1.93px;
  ${BreakpointDown.md`
    letter-spacing: 0;
  `}
`
const ProvideQualityTitle = styled.div`
 font-weight:900;
 line-height:1;
`
const PhilosophyContent = styled.div`
  color: #333333;
  margin-top:48px;
  margin-bottom:48px;
  ${BreakpointDown.lg`
    margin-top:38px;
  margin-bottom:15px;
  `}
   ${BreakpointDown.sm`
    margin-top:20px;
  margin-bottom:15px;
  `}
`

const OurPhilosophy = () => (
  <Section pt="60px" pb="0px" xpt="0px" mpt="40px">
    <ShadowWrapper>
      <div className="container">
        <Philosophy>
          <LeftWrapper>
            <InnerContent>
              <OurphilosophyTitle className="h2">Our philosophy :</OurphilosophyTitle>
              <QualityTitle className="h1">INTEGRITY + QUALITY = IQ. </QualityTitle>
              <ProvideQualityTitle className="h2">WYN is about providing quality services with integrity!</ProvideQualityTitle>
              <PhilosophyContent><strong>WYN Technologies</strong> is here to provide <strong>Whatever You Need,</strong> and we do so in a high-<strong>IQ</strong> manner (with both <strong>Integrity & Quality</strong>). Whether your needs entail <strong>Technological Operations, Expert Installation & Onsite Services</strong>, or <strong>Project Management Services</strong>, we’ve got you covered! </PhilosophyContent>
              <PhilosophyContent>At <strong>WYN,</strong> we believe that our thorough understanding of business finances & budgets, our focus on moral principles, and our clear communication approach are all key factors in delivering success for our customers. We’re always looking for areas of improvement, while also being intentional to keep our clients well-informed through every stage of every project.</PhilosophyContent>
              <Link to="/about-us"><PrimaryLinkButton text="READ OUR FULL STORY" /></Link>
            </InnerContent>
          </LeftWrapper>
          <RightWrapper>
            <CustomerSlider />
          </RightWrapper>
        </Philosophy>
      </div>
    </ShadowWrapper>
  </Section>
)
export default OurPhilosophy;