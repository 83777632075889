import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LineArrowIcon from '../../components/Icons/LineArrowIcon'
import BreakpointDown from "../../components/Media/BreakpointDown";
import SliderBg from '../../images/offer.jpg'
import OfferBg from '../../images/tag.png'

const SlickSliderInner = styled.div`
  position: relative;
  border-radius: 5px;
  padding: 25px 40px;
  background:url(${SliderBg});
  background-size:cover;
  background-position: center center;
  margin-left:20px;
  @media(max-width:1024px){
  max-width:330px;
 }
 ${BreakpointDown.lg`
    margin-right:auto;
    margin-left: auto;
 `}

  z-index: 2;
  ${BreakpointDown.md`
      padding:25px 25px;
      max-width:100%;
      margin-left:15px;
      margin-right:15px;
   `}
`;
const SlickSlider = styled.div`
  max-width: 440px;
  position: relative;
  margin-top: 40px;
  ${BreakpointDown.lg`
    max-width:100%;
  `}

  ${BreakpointDown.md`
   margin-top:20px;
  `}

  .slick-slider {
    .slick-arrow {
      display: none !important;
    }
  }
`;
const SlickItem = styled.div`
  display: block !important;
  outline: none;
  max-width: 100%;
  font-size: 18px;
`;
const ContentWrapper = styled.div`
 max-width:222px;
 color:#fff;
 font-size:18px;
 line-height:24px;
 ${BreakpointDown.md`
  line-height:20px;
  max-width: 152px;
  font-size:14px;
 `}
`
const SlickArrowTrack = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
const LeftArrow = styled.div`
  position: absolute;
  width: 18px;
  height: 30px;
   border-radius:0 30px 30px 0;
  box-shadow: 0px 10px 25px #143da733;
  background-color: #fff;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  display:none;
  @media (hover: hover) {
    &:hover {
     
    }
  }
  cursor: pointer;
  svg {
    fill: #fff;
    width: 16px;
    height: 12px;
    transform: rotate(268deg);
    margin-left:-14px;
  }
`;
const RightArrow = styled.div`
  position: absolute;
  width: 18px;
  height: 30px;
  box-shadow: 0px 10px 25px #143da733;
  border-radius: 0px;
  background-color: #fff;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  border-radius:30px 0 0 30px;
  display:none;
  @media (hover: hover) {
    &:hover {
     
    }
  }
  cursor: pointer;
  
 
  svg {
    fill: #fff;
    width: 16px;
    height: 12px;
    transform: rotate(90deg);
    margin-right:-14px;
  }
`;

const Offer = styled.div`
 width: 80px;
  height:60px;
  background:url(${OfferBg}) no-repeat;
  position:absolute;
  top: -34px;
  left: -22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#fff;
  font-size:14px;
  font-family: 'Comic Neue', cursive;
  font-weight:bold;
  ${BreakpointDown.md`
    top: -38px;
    left: -18px;
  `}
  span{
    transform:rotate(-20deg);
    margin-top: 7px;
    font-size:14px;
  }
`

function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <LineArrowIcon />
    </button>
  );
}
function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
    >
      <LineArrowIcon />
    </button>
  );
}
export default class OfferSliderCarousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      slidesToShow: 1,
      infinite: true,
      nextArrow: <CircleNextArrow />,
      prevArrow: <CirclePrevArrow />,
      arrow: false,
      speed: 400,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 1,
            arrows: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows: false,
            centerMode: true,
            centerPadding: "0",
            infinite: true,
          },
        },
      ],
    };
    return (


      <SlickSlider>
        <SlickSliderInner>
          <Offer><span>Recent</span></Offer>
          <SlickArrowTrack>
            <LeftArrow onClick={this.previous}>
              <LineArrowIcon />
            </LeftArrow>
            <RightArrow onClick={this.next}>
              <LineArrowIcon />
            </RightArrow>
          </SlickArrowTrack>
          <Slider {...settings} ref={(c) => (this.slider = c)}>
            {[1].map(item => (
              <SlickItem key={item}>
                <ContentWrapper>
                  Providing VOIP Phone Systems, Internet and Email Migration Services
                    </ContentWrapper>
              </SlickItem>
            ))}
          </Slider>
        </SlickSliderInner>
      </SlickSlider>
    );
  }
}
